import {
    $, addAction, doAction, INIT, GTM_INTERACTION,
} from '@situation/setdesign.util';

let pollInterval = false;

function pollFormStatus() {
    const $container = this;
    const formID = $container.attr('id');
    const $errors = $container.find('.form_question.required');
    const $form = $(`#${formID}`);
    const $questions = $form.find('.form_question');
    if ($errors.length || !$questions.length) {
        $container.removeClass('form-submitting');
        clearInterval(pollInterval);
    }
    if (!$questions.length) {
        $('body').addClass('form-submitted');
        $form.addClass('form-confirmation');
        doAction(GTM_INTERACTION, {
            type: 'submit', formID, success: true, label: formID,
        });
    }
}

addAction(INIT, () => {
    $(document).on('click', '.form_button_submit', (e) => {
        const $form = $(e.target).parents('form').parent();
        $form.addClass('form-submitting');
        pollInterval = setInterval(pollFormStatus.bind($form), 500);
    });
});
