import {
    $, addAction, addFilter, applyFilters, removeFilter, INIT,
} from '@situation/setdesign.util';

function shareBtnInit($el) {
    const $context = $el || $(document);
    const $copyLinkBtn = $context.find('.copy-link');
    let $container = $copyLinkBtn.parent('.copy-link-container');
    if (!$container.length) {
        $container = $(`<div class="copy-link-container"><input class="copy-link-url" type="text" value="${window.location}" readonly disabled></div>`);
        $container.append($copyLinkBtn.clone());
        $copyLinkBtn.replaceWith($container);
        $container.find('.copy-link').on('click', (e) => {
            e.preventDefault();
            const copyText = $container.find('.copy-link-url').val();
            navigator.clipboard.writeText(copyText);
            $container.addClass('copy-link-container--copied');
            setTimeout(() => {
                $container.removeClass('copy-link-container--copied');
            }, 1000);
        });
    }
    let url = applyFilters('currentShareUrl', window.location);
    try {
        url = new URL(url, window.location.origin);
    } catch (e) {
        return;
    }
    if (url.host === window.location.host) {
        url = SetDesign.updateUrlParameter(String(url), 'utm_term', 'share');
    }
    $container.find('.copy-link-url').val(url);
}

addAction(INIT, () => {
    shareBtnInit($(document));
    $('[data-share-url]').on('click', (e) => {
        const $btn = $(e.currentTarget);
        const url = $btn.data('shareUrl');
        addFilter('currentShareUrl', () => url);
    });
});
addAction('modalContentLoaded', shareBtnInit);
addAction('showModal', shareBtnInit);
addAction('hideModal', () => removeFilter('currentShareUrl'));
