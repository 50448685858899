import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addFilter('css-vars/register', (styles) => ({
    ...styles,
    'header-height': () => `${$('.header-navigation').outerHeight(true)}px`,
    'footer-height': () => `${$('.footer-nav').outerHeight(true)}px`,
}));
addAction(INIT, () => {
    $('.button-group').addClass('col-reset-exclude');
});
