import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.numbered-list').each((index, el) => {
        const $el = $(el);
        const $readMore = $el.find('[data-action="read-more"]');
        const $readLess = $el.find('[data-action="read-less"]');
        $readMore.click((e) => {
            e.preventDefault();
            $el.find('li.exclude ~ li').show();
            $(e.currentTarget).parent('li').hide();
        });
        $readLess.click((e) => {
            e.preventDefault();
            $el.find('li.exclude ~ li').hide();
            $readMore.parent('li').show();
        });
    });
});
