import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

/**
 * Since we are overriding the close button with an SVG,
 * we need to adjust our close "listening" logic to accommodate
 */

addAction(INIT, () => {
    $('body').on('click', '.modal', (e) => {
        const $target = $(e.target);
        if ($target.parents('.modal__close').length) {
            doAction('hideModal', $target.parents('.modal'));
        }
    });
});
addAction('showModal', ($modal) => {
    $modal.find('[data-modal-content]').each((index, el) => {
        const $el = $(el);
        const content = atob($el.data('modalContent'));
        $el.replaceWith(content);
        SetDesign.updateLayout();
        doAction('refreshLazyLoad', $modal);
        doAction('modalContentLoaded', $modal);
    });
});
